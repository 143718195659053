/**
 * JSO
 * ibergour shop javascript 
 */
$(document).ready(function(){

	window.idioma_usuario = function (){
		return window.location.pathname.substring(1, 3)
	}

	window.fill_cart_dropdown = function (summary = true, show = false){
		$.ajax({
			url: "/" + idioma_usuario() + "/api/cart_dropdown.html?referrer=" + encodeURI(window.location.href) + (summary ? '&summary=1' : ''),
			type: "GET",
			cache: false,
			success: function(data){
				$('#cart-dropdown').html(data.html_dropdown)
				$('#my-order-summary').html(data.html_summary)
				$('.warning_pedido_within_limites_destino').html(data.warning_pedido_within_limites_destino)
				poll_delivery_time_carrito(0)
				// In case there is any tooltip within the new HTML in the DOM
				$('[data-toggle="tooltip"]').tooltip({sanitize: false})
				// Event for deleting cart items from the dropdown
				$('#cart-dropdown a[data-url-delete]').on('click', function (e) {
					e.preventDefault();
					del_from_cart_dropdown($(this).attr('data-url-delete'));
				});
				if(show) $('#cartdetails').trigger('click');
			},
			dataType: "json",
// 			timeout: 5000
		})
	}

	window.append_ephemeral_data = function (ephemeral_ecommerce_data_html){
		if(!ephemeral_ecommerce_data_html){return}
		// Create dummy parent element since ephemeral_ecommerce_data_html contains full HTML (including <script> and </script> tags)
		var dummydiv = document.createElement('div')
		dummydiv.innerHTML = ephemeral_ecommerce_data_html
// 		document.head.appendChild(dummydiv.firstChild) // firstChild gets us the original HTML (this won't work because the javascript won't be executed because we are in an AJAX call)
		eval(dummydiv.firstChild.innerHTML) // https://stackoverflow.com/a/511273
	}
	
	window.del_from_cart_dropdown = function (url_del){
		$.ajax({
			url: url_del,
			type: "GET",
			cache: false,
			success: function(data){
				if(data.result != 'ok'){return}
				if($(location).attr('pathname').match(/^\/..\/carrito$/)){location.reload()}
				fill_cart_dropdown(true, true)
				append_ephemeral_data(data.ephemeral_ecommerce_data_html)
			},
			dataType: "json",
// 			timeout: 5000
		})
	}
	
	window.update_cart = function (input, url_attr){
		$.ajax({
			url: input.attr(url_attr),
			type: "GET",
			cache: false,
			success: function(data){
				if(data.result != 'ok'){return}
				input.val(data.datos_items[0].cantidad)
				input.closest('.cart-item').find('.cart-item-unit-price').html(data.datos_items[0].importe_unitario_moneda_display)
				input.closest('.cart-item').find('.cart-item-total-price').html(data.datos_items[0].importe_total_moneda_display)
				if(input.val() > 1){
					// Make sure decrease icon is a minus sign
					input.siblings('.btn-items-decrease').find('i').removeClass('fa-trash-alt').addClass('fa-minus-circle')
				}else if(input.val() == 1){
					// Make sure decrease icon is a trash bin
					input.siblings('.btn-items-decrease').find('i').removeClass('fa-minus-circle').addClass('fa-trash-alt')
				}else if(input.val() == 0){
					// Remove item line
					var cart_body = input.closest('.cart-body')
					var cart_item = input.closest('.cart-item')
					cart_item.hide('slow', function(){
						cart_item.remove();
						// If cart is empty, show empty-cart-text
						if(cart_body.children().length == 0){
							$('#no-cart').removeClass('d-none');
							$('#yes-cart').hide('slow', function(){
								$('#yes-cart').remove();
							})
						}
					})
				}
				fill_cart_dropdown(true)
				append_ephemeral_data(data.ephemeral_ecommerce_data_html)
			},
			dataType: "json",
// 			timeout: 5000
		})
	}

	// Detect pressed caps-lock (useful for password fields) - based on https://stackoverflow.com/a/2308911/1091231
	window.capLock = function (e, input){
		var kc = e.keyCode ? e.keyCode : e.which;
		var sk = e.shiftKey ? e.shiftKey : kc === 16;
		var visibility = ((kc >= 65 && kc <= 90) && !sk) || 
				((kc >= 97 && kc <= 122) && sk) ? true : false;
		if(visibility){
			$(input).tooltip({title: $(input).attr('data-tooltip')}).tooltip('show')
		}else{
			$(input).tooltip('dispose')
		}
	}

	// https://stackoverflow.com/a/22480938/1091231
	window.isScrolledIntoView = function (el) {
		var rect = el.getBoundingClientRect();
		var elemTop = rect.top;
		var elemBottom = rect.bottom;

		// Only completely visible elements return true:
		var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
		// Partially visible elements return true:
		//isVisible = elemTop < window.innerHeight && elemBottom >= 0;
		return isVisible;
	}

	// Used in BD-stored formulas that determine final weight in product transformations
	window.pow = function (x,y){return Math.pow(x,y);}
	window.M_E = Math.E;

	// Needed for product page...
	window.number_format = function (number, decimals, dec_point, thousands_sep) {
		var n = !isFinite(+number) ? 0 : +number, 
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec) {
					var k = Math.pow(10, prec);
					return '' + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	}
	// ...Needed
	
		// Tiempos de entrega del carrito
	window.poll_delivery_time_carrito = function (delay){
		if(!$('#delivery_time_text').length) return;
    setTimeout(function(){
        $.ajax({
            url: "/" + idioma_usuario() + "/api/delivery_time_text_carrito.html",
            type: "GET",
            cache: false,
            success: function(data){
							$('#delivery_time_text').html(data);
            },
            dataType: "json",
            complete: poll_delivery_time_carrito(60000),
//             timeout: 5000
        })
    }, delay);
  }

});
